/* eslint-disable func-names */
import axios from 'axios';
import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import CotationFormView from './cotation-form-view.component';

const CotationForm: React.FunctionComponent = () => {
  const [formValues, setFormValues] = React.useState({
    name: '',
    phone: '',
    email: '',
    infos: '',
  });
  const [loading, setLoading] = React.useState(false);
  const handleChangeForm = (name: any) => (event: any) => {
    setFormValues({ ...formValues, [name]: event.target.value });
  };
  const onSubmitEvent = (event: any) => {
    event.preventDefault();
    setLoading(true);
    axios
      .post(
        'https://mvgojapwmi.execute-api.us-east-2.amazonaws.com/default/sendQuotationEmail',
        { ...formValues, location: window?.location?.href }
      )
      .then(function (response: any) {
        setFormValues({
          name: '',
          phone: '',
          email: '',
          infos: '',
        });
      })
      .catch(function (error: any) {
        console.log(error);
      })
      .then(function () {
        setLoading(false);
      });
  };

  const data = useStaticQuery(graphql`
    query CotationFormQuery {
      markdownRemark(frontmatter: { fileName: { eq: "cotationForm" } }) {
        frontmatter {
          buttonWhatsappText
          buttonWhatsappLink
          phoneButtonText
          phoneButtonLink
        }
      }
    }
  `);
  const cotationFormData = data.markdownRemark.frontmatter;

  return (
    <CotationFormView
      onSubmitEvent={onSubmitEvent}
      whatsappButtonLink={cotationFormData.buttonWhatsappLink}
      whatsappButtonText={cotationFormData.buttonWhatsappText}
      phoneButtonLink={cotationFormData.phoneButtonLink}
      phoneButtonText={cotationFormData.phoneButtonText}
      formValues={formValues}
      handleChangeForm={handleChangeForm}
      loading={loading}
    />
  );
};

export default CotationForm;
