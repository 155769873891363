import axios from 'axios';
import * as React from 'react';

import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@material-ui/core';
import { Phone, WhatsApp } from '@material-ui/icons';

import {
  UnstyledLink,
  UnstyledNativeLink,
} from '../atm.unstyled-link/unstyled-link.styled';
import { Spacing } from '../obj.constants/constants';
import { FormWrapperPaperStyled } from './cotation-form.styled';

interface ICotationFormProps {
  onSubmitEvent: (event: React.FormEvent) => void;
  whatsappButtonLink: string;
  whatsappButtonText: string;
  phoneButtonLink: string;
  phoneButtonText: string;
  formValues: {
    name: string;
    phone: string;
    email: string;
    infos: string;
  };
  handleChangeForm: (fieldName: string) => (event: unknown) => void;
  loading: boolean;
}

const CotationFormView: React.FunctionComponent<ICotationFormProps> = (
  props
) => {
  const {
    onSubmitEvent,
    whatsappButtonLink,
    whatsappButtonText,
    formValues,
    handleChangeForm,
    loading,
    phoneButtonLink,
    phoneButtonText,
  } = props;

  return (
    <FormWrapperPaperStyled elevation={3}>
      <Box padding={Spacing.XLarge}>
        <form autoComplete="off" onSubmit={onSubmitEvent}>
          <Box paddingBottom={Spacing.Large}>
            <Typography variant="h5" component="h3">
              Solicite sua cotação
            </Typography>
          </Box>
          <Box paddingBottom={Spacing.Large}>
            <TextField
              id="name"
              label="Nome completo"
              variant="outlined"
              fullWidth
              value={formValues.name}
              onChange={handleChangeForm('name')}
              disabled={loading}
              required
            />
          </Box>
          <Box paddingBottom={Spacing.Large}>
            <TextField
              id="phone"
              label="Telefone"
              variant="outlined"
              fullWidth
              value={formValues.phone}
              onChange={handleChangeForm('phone')}
              disabled={loading}
              required
            />
          </Box>
          <Box paddingBottom={Spacing.Large}>
            <TextField
              id="email"
              label="E-mail"
              variant="outlined"
              fullWidth
              value={formValues.email}
              onChange={handleChangeForm('email')}
              disabled={loading}
            />
          </Box>
          <Box paddingBottom={Spacing.Large}>
            <TextField
              id="infos"
              label="Informações adicionais"
              placeholder="Coloque aqui informações para agilizar a cotação como modelo do carro, dúvidas sobre o produto ou corretora"
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              value={formValues.infos}
              onChange={handleChangeForm('infos')}
              disabled={loading}
            />
          </Box>
          <Box marginBottom={Spacing.Medium}>
            {loading ? (
              <Box textAlign="center">
                <CircularProgress />
              </Box>
            ) : (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
              >
                Solicitar
              </Button>
            )}
          </Box>

          <Box marginBottom={Spacing.Medium}>
            <UnstyledLink to={whatsappButtonLink} target="_blank">
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                startIcon={<WhatsApp />}
              >
                {whatsappButtonText}
              </Button>
            </UnstyledLink>
          </Box>
          <UnstyledNativeLink href={phoneButtonLink} target="_blank">
            <Button
              variant="text"
              color="primary"
              fullWidth
              startIcon={<Phone />}
            >
              {phoneButtonText}
            </Button>
          </UnstyledNativeLink>
        </form>
      </Box>
    </FormWrapperPaperStyled>
  );
};

export default CotationFormView;
